import React from 'react'

export default function Header() {
  return (
    <header>
    <nav
      className="
        flex flex-wrap
        items-center
        justify-between
        w-full
        py-4
        md:py-0
        px-4
        text-lg text-gray-700
        bg-gray-800
      "
    >
      <div className="h-6 w-6 ml-10">
        <a href="/">
            <img src="https://nebulacore.tech/icons/icon-256x256.png?v=5ae2a892e5099e9525de9fd8ece1b396" alt="Nebula Core Technologies Logo"/>
        </a>
      </div>
      
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="menu-button"
          className="h-6 w-6 cursor-pointer md:hidden block"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      
      <div className="hidden w-full md:flex md:items-center md:w-auto" id="menu">
        <ul
          className="
            pt-4
            text-base text-gray-400
            md:flex
            md:justify-between 
            md:pt-0"
        >
          <li>
            <a className="md:p-4 py-2 block hover:text-purple-400" href="/about"
              >About</a
            >
          </li> 
          <li>
            <a className="md:p-4 py-2 block hover:text-purple-400" href="/team"
              >Team</a
            >
          </li> 
          <li>
            <a
              class="md:p-4 py-2 block hover:text-purple-400 text-purple-500"
              href="/projects"
              >Projects</a
            >
          </li>
        </ul>
      </div>
  </nav>
  </header>
  )
}
